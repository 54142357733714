import { liveQuery } from "dexie";
import { useSubscription } from "./use-subscription";
import { useMemo, useState } from "react";
export function useLiveQuery(querier, dependencies, defaultResult) {
    var _a = useState(defaultResult), lastResult = _a[0], setLastResult = _a[1];
    var subscription = useMemo(function () {
        // Make it remember previus subscription's default value when
        // resubscribing (á la useTransition())
        var currentValue = lastResult;
        var observable = liveQuery(querier);
        return {
            getCurrentValue: function () { return currentValue; },
            subscribe: function (onNext, onError) {
                var esSubscription = observable.subscribe(function (value) {
                    currentValue = value;
                    setLastResult(value);
                    onNext(value);
                }, onError);
                return esSubscription.unsubscribe.bind(esSubscription);
            }
        };
    }, 
    // Re-subscribe any time any of the given dependencies change
    dependencies || []);
    // The value returned by this hook reflects the current result from the querier
    // Our component will automatically be re-rendered when that value changes.
    var value = useSubscription(subscription);
    return value;
}

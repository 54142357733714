(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dexie"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["dexie", "react"], factory);
	else if(typeof exports === 'object')
		exports["DexieReactHooks"] = factory(require("dexie"), require("react"));
	else
		root["DexieReactHooks"] = factory(root["Dexie"], root["React"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__301__, __WEBPACK_EXTERNAL_MODULE__888__) {
return 